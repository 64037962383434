import "./App.css";
import React from "react";
import Start from "./components/Start";
import Navigation from "./components/Navigation";
import Missed from "./components/Missed";
import SocialMedia from "./components/SocialMedia";
import Partners from "./components/Partners";
import Sponsors from "./components/Sponsors";
import Contact from "./components/Contact"
import { Route } from "react-router-dom";
import "bootstrap/dist/css/bootstrap.min.css";
import { initializeApp } from "firebase/app";
import { getAnalytics } from "firebase/analytics";

// app's Firebase project configuration
const firebaseConfig = {
  apiKey: "AIzaSyD6MYbix_rTVtl7a8SaTAWNU8Okkkyw-Cg",

  authDomain: "we-it-website.firebaseapp.com",

  databaseURL:
    "https://we-it-website-default-rtdb.europe-west1.firebasedatabase.app",

  projectId: "we-it-website",

  storageBucket: "we-it-website.appspot.com",

  messagingSenderId: "436868846149",

  appId: "1:436868846149:web:200f07d813a5914afcc92c",

  measurementId: "G-FRKEV6DEPT",
};

const app = initializeApp(firebaseConfig);
const analytics = getAnalytics(app);

function App() {
  return (
    <div>
      <box className="bg">
        <style>
          {
            'body {background-image: url("../img/background.jpeg");background-repeat: no-repeat;background-size: cover;background-attachment: fixed;}'
          }
        </style>
        <Navigation />
        <SocialMedia />
        <Route exact path="/" component={Start} />
        <Route exact path="/Missed" component={Missed} />
        <Route exact path="/Sponsors" component={Sponsors} />
        <Route exact path="/Partners" component={Partners} />
        <Route exact path="/Contact" component={Contact} />
      </box>
    </div>
  );
}

export default App;
