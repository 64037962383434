function Contact() {
    return (
      <div class="Contact">
        <iframe 
          class="Contact-iframe"
          src="https://us13.list-manage.com/contact-form?u=06148bddfe5573866d1309192&form_id=7b24e50fceae4ba342260f66726b97c2">
          scrolling="no"
          allowfullscreen
        </iframe>
      </div>
    );
  }
  
  export default Contact;